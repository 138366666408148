<template>
  <b-row class="match-height">
    <b-col
      v-for="item in gallery"
      :key="item.id"
      cols="12"
      md="4"
    >
      <b-card
        no-body
        class="cursor-pointer"
        @click="toPage(item.id)"
      >
        <div class="cover-image d-flex align-items-center height-300 overflow-hidden rounded-top">
          <b-img
            :src="folderUrl + item.cover"
            fluid-grow
          />
        </div>
        <b-card-body>
          <b-card-title class="font-small-4 text-primary mb-0">
            {{ item.title }}
          </b-card-title>
          <b-card-text class="text-muted">
            {{ item.content }}
          </b-card-text>
        </b-card-body>
        <b-card-footer>
          <small class="text-muted">{{ moment(item.created).format('DD.MM.YYYY') }}</small>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody, BCardTitle, BCardText, BCardFooter, BImg,
} from 'bootstrap-vue'

export default {
  name: 'Gallery',
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardFooter,
    BImg,
  },
  data() {
    return {
      folderUrl: `${this.$store.state.app.baseURL}/media/portal/gallery/`,
    }
  },
  computed: {
    gallery() {
      return this.$store.getters['gallery/getGallery']
    },
  },
  created() {
    this.getGallery()
  },
  methods: {
    getGallery() {
      this.$store.dispatch('gallery/gallery')
    },
    toPage(id) {
      this.$router.push(`/fotograf-galerisi/${id}`)
    },
  },
}
</script>

<style scoped>
.album-navbar{
  width: 300px;
}
.cover-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
